import React from 'react';
import Layout from '../components/Layout';
import {Link} from 'gatsby';
import {
    MDBContainer,
    MDBRow,
    MDBCol
} from 'mdbreact';

const Error404 = () => {
    return(
        <Layout title='Kelsey Makida | Page not found'
            noRoboIndex>
            <MDBContainer>
                <MDBRow className='align-content-center text-center' style={{height:'100vh'}}>
                    <MDBCol>
                        <h1>404</h1>
                        <h2>Let's go <Link to='/'>home</Link></h2>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </Layout>
    )
}

export default Error404;